<template>
	<div class="campaign">
		<div v-if="preload">
			<CampaignPreload />
			<div class="campaign__values card blinking-item">
				<div class="campaign__values-grid">
					<span
						v-for="i in statValues.length"
						:key="i"
						class="campaign-value campaign-preload-value blinking-item"
					/>
				</div>
			</div>
			<div class="card blinking-item campaign-preload-main">
				<div class="campaign-preload-nav">
					<span class="blinking-item" /><span class="blinking-item" />
					<span class="blinking-item" /><span class="blinking-item" />
				</div>
				<Spinner class="blinking-item" />
			</div>
		</div>
		<template v-else-if="campaign">
			<CampItem
				:campaign="campaign"
				:statistics-loading="statisticsLoading"
				@campaignRemoved="goToCampaigns"
			/>
			<div
				v-if="!isDraft"
				class="campaign__values card"
			>
				<section
					class="campaign__values-grid"
					aria-label="Campaign's leads digits"
				>
					<CampaignValue
						v-for="statItem in statValues"
						:key="statItem.icon"
						v-tooltip="statItem.tooltip"
						:stat="statItem"
						:statistics-loading="statisticsLoading"
						:campaign="campaign"
					/>
				</section>
			</div>

			<div class="card card--padding">
				<div class="card-tabs">
					<nav
						class="card-tabs__nav"
						aria-label="Campaign navigation"
					>
						<ul class="card-tabs__list">
							<li
								v-for="tab in tabsList"
								:key="tab.routeName"
								class="card-tabs__item"
							>
								<router-link
									v-tooltip="tab.highlight ? tab.draftTooltip : null"
									:to="routeLink(tab.routeName)"
									class="card-tabs__link"
									active-class="card-tabs__link--active"
									:class="{ 'card-tabs__link--highlight': tab.highlight }"
									:exact="tab.routeName === 'campaign'"
								>
									{{ tab.name }}
								</router-link>
							</li>
						</ul>
					</nav>
				</div>
				<transition
					name="fade"
					mode="out-in"
				>
					<router-view
						:campaign="campaign"
						@campaignUpdated="freshUpCampaign"
					/>
				</transition>
			</div>
		</template>
		<NotFound v-else />
	</div>
</template>

<script>
import CampaignPreload from "@/js/components/Campaign/CampaignPreload";
import CampItem from "@/js/components/Campaign/CampItem";
import CampaignValue from "@/js/components/Campaign/CampaignValue";
import getCampaignsStatistics from "@/js/mixins/getCampaignsStatistics.mixin";
import sequence from "@/js/mixins/sequence.mixin";
import eventBus from "@/event-bus";

export default {
	name: "Campaign",
	components: {
		CampItem,
		CampaignValue,
		CampaignPreload,
		NotFound: () => window.compLoader(import("@/js/views/404Component"))
	},
	metaInfo() {
		return { title: this.campaign ? `${this.campaign.name}` : "Campaign" };
	},
	mixins: [getCampaignsStatistics, sequence],
	provide() {
		return {
			campaignInfo: this.campaignInfoToProvide
		}
	},
	beforeRouteUpdate(to, from, next) {
		this.getCampaignsStatistics([this.campaign], undefined, false);
		this.checkForSequenceIsSaved(to, from, next);
	},
	beforeRouteLeave(to, from, next) {
		this.clearCampaign();
		this.checkForSequenceIsSaved(to, from, next);
	},
	data: () => ({
		campaign: null,
		campaignInfoToProvide: {},
		sequence: null,
		preload: true,
		tabsList: [
			{
				name: "Audience",
				routeName: "campaign",
				draftTooltip: "Add leads from LinkedIn to this campaign to activate it",
				highlight: false
			},
			{
				name: "Sequence",
				routeName: "campaign-sequence",
				draftTooltip: "Complete the sequence to activate your campaign",
				highlight: false
			},
			{
				name: "Statistics",
				routeName: "campaign-statistics",
				highlight: false
			},
			{
				name: "Settings",
				routeName: "campaign-settings",
				draftTooltip: "Make sure you've finished setting up your campaign here",
				highlight: false
			}
		],
		leavingSequencePage: false
	}),
	computed: {
		campaignID() {
			return +this.$route.params.campaignId;
		},
		stat() {
			return this.campaign && this.campaign.statistics;
		},
		isDraft() {
			return this.campaign.status === "DRAFT";
		},
		statValues() {
			const campLeadsUrl = `/campaigns/${this.campaignID}/leads`;
			return [
				{
					icon: "lead",
					value: this.stat ? this.stat.totalLeads : 0,
					text: "All leads",
					url: campLeadsUrl,
					tooltip: "Total leads that are already uploaded in the sequence"
				},
				{
					icon: "spin-right",
					value: this.stat ? this.stat.inProgressLeads : 0,
					text: "In progress",
					url:
						campLeadsUrl +
						"?leadStatuses=READY_FOR_PROCESSING&blacklisted=false&excludeTypes=WAITING&excludeTypes=LEFT&pageName=In+progress",
					tooltip: "Leads that are currently going through the sequence in the campaign"
				},
				{
					icon: "doublecheck",
					value: this.stat ? this.stat.finishedLeads : 0,
					text: "Completed all steps",
					url: campLeadsUrl + "?includeTypes=FINISHED&pageName=Completed",
					tooltip: "Leads that completed all steps in the sequence"
				},
				{
					icon: "waiting",
					value: this.stat ? this.stat.waitingLeads : 0,
					text: "Awaiting",
					url: campLeadsUrl + "?includeTypes=WAITING&pageName=Awaiting",
					tooltip:
						"Leads that are waiting in the sequence until the connection request is accepted / InMail replied"
				},
				{
					icon: "pause",
					value: this.stat ? this.stat.pausedLeads : 0,
					text: "Paused",
					url: campLeadsUrl + "?includeTypes=PAUSED&pageName=Paused",
					tooltip:
						"Leads that replied to your Linkedin message, connection request or InMail are now paused in the sequence"
				},
				{
					icon: "failed-lead",
					value: this.stat ? this.stat.failedLeads : 0,
					text: "Failed",
					url: campLeadsUrl + "?includeTypes=FAILED&blacklisted=false&pageName=Failed",
					tooltip: "Leads that have left the sequence due to a failed attempt to perform an action"
				},
				{
					icon: "sequence-icon-blacklisted",
					value: this.stat ? this.stat.blacklistedLeads : 0,
					text: "Blacklisted",
					url: campLeadsUrl + "?includeTypes=BLACKLISTED&pageName=Blacklisted",
					tooltip: "Leads that have been manually blacklisted"
				}
			];
		}
	},
	watch: {
		campaignID() {
			this.getCampaign();
		}
	},
	async created() {
		eventBus.$on("campaignUpdated", this.getCampaign);
		eventBus.$on("sequencePopupUpdated", (val = true) => {
			this.sequencePopupUpdated = val;
		});
		await this.getCampaign();
	},
	beforeDestroy() {
		this.clearCampaign();
	},
	methods: {
		checkForSequenceIsSaved(to, from, next) {
			if (from.name === "campaign-sequence" && this.sequencePopupUpdated) {
				eventBus.$emit("leavingSequencePage", to);
				next(false);
			} else {
				next();
			}
		},
		routeLink(name) {
			return {
				name: name,
				params: {
					campaignId: this.campaign.id
				}
			};
		},
		async freshUpCampaign(campaign) {
			if (campaign) this.campaign = campaign;
			await this.getCampaignsStatistics([this.campaign]);
			this.checkCampaignIsDraft();

			if (this.campaign.zapierError && this.campaign.zapierUrl) {
				this.tabsList.find((tab) => tab.routeName === "campaign-settings").highlight = true;
			}
		},
		async getCampaign() {
			try {
				this.preload = true;
				const res = await this.$store.dispatch("getCampaignById", this.campaignID);
				await this.freshUpCampaign(res);
				Object.assign(this.campaignInfoToProvide, res)
			} finally {
				this.preload = false;
			}
		},
		goToCampaigns() {
			this.$router.push("/campaigns");
		},
		clearCampaign() {
			eventBus.$off("campaignUpdated");
		},
		checkCampaignIsDraft() {
			this.tabsList.forEach((tab) => (tab.highlight = false));

			if (this.isDraft) {
				const haveLeads = this.campaign.statistics.leadsListsSize;
				const { sequenceCompleted } = this.campaign;

				if (!haveLeads) this.tabsList.find((tab) => tab.routeName === "campaign").highlight = true;
				if (!sequenceCompleted)
					this.tabsList.find((tab) => tab.routeName === "campaign-sequence").highlight = true;

				this.tabsList.find((tab) => tab.routeName === "campaign-settings").highlight = true;

				if (haveLeads) {
					const redirectLink = this.routeLink(!sequenceCompleted ? "campaign-sequence" : "");
					this.$router.push(redirectLink);
				}
			}
		}
	}
};
</script>

<style lang="sass">
@import "@/styles/components/tabs/card-tabs"
.campaign
	min-height: calc(100vh - 60px)
	&__
		&values
			margin-top: -20px
		&values-grid
			--values-gap: 15px
			display: flex
			gap: var(--values-gap)
			flex-wrap: wrap
			@media screen and (max-width: $bigLaptop)
				--values-gap: 11px
			.campaign-value
				width: 150px
				@media screen and (max-width: $mobile)
					width: calc(50% - (var(--values-gap) / 2))

.campaign-preload-value
	&::before
		content: ""
		display: block
		height: 23px
		width: 35%
		border-radius: 20px
		background-image: linear-gradient(to right, cl(base-theme, .3) 0%, cl(light-gray, .6) 100%)
		margin-bottom: 5px
		@media screen and (max-width: $bigLaptop)
			height: 20px
	&::after
		content: ""
		display: block
		height: 13px
		width: 50%
		border-radius: 20px
		background-color: cl(_gray, .3)
.campaign-preload-main
	height: calc(100vh - 280px)
.campaign-preload-nav
	display: flex
	align-items: center
	margin: 0 calc(var(--card-offset-x) * -1)
	border-bottom: 1px solid cl(light-dirty)
	padding: 5px 0 14px
	span
		width: 61px
		height: 15px
		border-radius: 20px
		background-color: cl(base-theme, .3)
		margin: 0 15px
</style>
