<template>
	<article
		class="leads-pack"
		aria-label="Pack of leads"
	>
		<div
			class="leads-pack__grid"
			:class="{'leads-pack__grid--email' : isEmailReplyFeatureVisible}"
		>
			<component
				:is="totalLeadsCount ? 'router-link' : 'div'"
				v-tooltip.bottom-start="{
					delay: { show: 500 },
					content: searchType(pack)
				}"
				:to="totalLeadsCount ? leadsURL : null"
				class="picto picto--rounded"
				style="grid-area: picto"
				:aria-label="searchType(pack)"
			>
				<Icon :icon="isLISearch(pack) ? 'in' : 'links'" />
			</component>

			<div
				class="leads-pack__main"
				style="grid-area: main"
			>
				<component
					:is="totalLeadsCount ? 'router-link' : 'div'"
					:to="totalLeadsCount ? leadsURL : null"
					:title="packName"
					class="leads-pack__name"
					:class="{ 'leads-pack__name--link': totalLeadsCount }"
				>
					{{ packName }}
				</component>

				<LeadsIndicators
					v-if="!campaignCreating && !isDraft"
					class="leads-pack__indicators"
					:statistics="pack.statistics"
					:pack="pack"
					:statistics-loading="statisticsLoading"
					:campaign="campaign"
					for-leads-packs
				/>
			</div>

			<div style="grid-area: total">
				<CampaignStatisticsDisplaying
					v-if="!campaignCreating"
					:campaign="campaign"
					:pack="pack"
					:statistics-loading="statisticsLoading"
					field="totalLeadsCount"
				>
					<template #icon>
						<Icon
							icon="lead"
							stroke="var(--base-theme-color)"
							w="16"
							h="16"
						/>
					</template>
					All leads
				</CampaignStatisticsDisplaying>
			</div>

			<div style="grid-area: status">
				<div
					v-if="!campaignCreating && !isDraft"
					class="leads-pack__status"
				>
					<div
						v-if="searchComplete"
						class="leads-pack__collected"
					/>
					<CircleProgress
						v-else
						v-tooltip="searchPausedTooltip"
						:style="{ '--progress-size': '54px' }"
						:current-value="pack.leadsNumberCollected"
						:max-value="pack.leadsNumberToCollect"
						:disabled="!!searchPausedTooltip"
						digits-indicate
					/>
					<div>
						<div
							v-tooltip="searchPausedTooltip"
							:class="{ ph: searchPausedTooltip }"
						>
							<span v-if="searchComplete">Retrieved</span>
							<span
								v-else
								class="leads-pack__status-text"
							>Retrieving</span>
							from
							<SearchSource
								:pack="pack"
								:download-file-function="downloadFile"
								:disabled-text="!!searchPausedTooltip"
							/>
						</div>

						<button
							class="leads-pack__report-button"
							aria-haspopup="true"
							@click="showReport"
						>
							View report
						</button>
					</div>
				</div>
				<div v-else>
					{{ pack.leadsNumberToCollect }} Lead{{ pack.leadsNumberToCollect === 1 ? "" : "s" }}
					<div class="leads-pack__small">Added</div>
				</div>
			</div>

			<div
				v-if="!campaignCreating"
				class="leads-pack__separator"
				style="grid-area: separ1"
			/>

			<div style="grid-area: accept">
				<CampaignStatisticsDisplaying
					v-if="!campaignCreating"
					:campaign="campaign"
					:pack="pack"
					:statistics-loading="statisticsLoading"
					field="acceptanceRate"
					count-field="acceptanceCount"
					filter="INVITE_ACCEPTED"
				>
					Acceptance Rate
				</CampaignStatisticsDisplaying>
				<template v-else>
					<SearchSource
						:pack="pack"
						:download-file-function="downloadFile"
					/>
					<div class="leads-pack__small">Added from</div>
				</template>
			</div>

			<div style="grid-area: reply">
				<CampaignStatisticsDisplaying
					v-if="!campaignCreating"
					:campaign="campaign"
					:pack="pack"
					:statistics-loading="statisticsLoading"
					field="replyRate"
					count-field="replyCount"
					filter="MESSAGE_RESPONDED"
				>
					Reply rate
					<InfoTip
						size="12"
						stroke="var(--_gray-color)"
						tip="The number of leads that responded divided by the total number of leads reached via InMail, messages, and connection requests"
					/>
				</CampaignStatisticsDisplaying>
			</div>



			<div
				v-if="!campaignCreating"
				class="leads-pack__separator"
				style="grid-area: separ2"
			/>


			<template v-if="!campaignCreating && isEmailReplyFeatureVisible">
				<div style="grid-area: email-open">
					<CampaignStatisticsDisplaying
						:campaign="campaign"
						:pack="pack"
						:statistics-loading="statisticsLoading"
						field="emailBounceRate"
						count-field="emailBounceCount"
					>
						Email bounce rate
					</CampaignStatisticsDisplaying>
				</div>

				<div style="grid-area: email-reply">
					<CampaignStatisticsDisplaying
						:campaign="campaign"
						:pack="pack"
						:statistics-loading="statisticsLoading"
						field="emailReplyRate"
						count-field="emailReplyCount"
					>
						Email Reply rate
					</CampaignStatisticsDisplaying>
				</div>

				<div
					class="leads-pack__separator"
					style="grid-area: separ3"
				/>
			</template>

			<div style="grid-area: date">
				<time>{{ $mmm_dd_yyyy(pack.createdAt) }}</time>
				<span class="leads-pack__small">Creation date</span>
			</div>

			<div style="grid-area: context">
				<ContextMenu>
					<li class="leads-pack__context-added-from">
						<a
							v-if="isLISearch(pack)"
							:href="pack.searchUrl"
							target="_blank"
						>
							<Icon
								icon="link"
								:w="15"
								:h="17"
							/>
							LinkedIn search URL
						</a>
						<button
							v-else
							@click="downloadFile"
						>
							<Icon
								icon="download"
								:w="15"
								:h="17"
							/>
							CSV file
						</button>
					</li>
					<li>
						<button
							aria-haspopup="true"
							@click="openRemovingPopup"
						>
							<Icon
								icon="trash"
								:w="15"
								:h="17"
							/>
							Remove
						</button>
					</li>
				</ContextMenu>
			</div>
		</div>

		<transition name="fade">
			<LeadsReport
				v-if="reportPopup"
				:config="pack"
				:leads-url="leadsURL"
				@close="hideReport"
			/>
		</transition>

		<transition name="fade">
			<Popup
				v-if="removingPopup"
				small
				aria-label="Remove list of leads (Popup)"
				@close="closeRemovingPopup"
			>
				<template #header>
					<icon
						icon="danger"
						:w="24"
						:h="24"
						fill="var(--w-color)"
					/>
					<span>Warning</span>
				</template>
				<div>
					Do you really want to remove list of leads <b>{{ packName }}</b>?
				</div>
				<br>
				<footer class="popup__footer">
					<AsyncButton
						:async-f="removeLeadPack"
						class="btn btn--accent"
					>
						Remove list
					</AsyncButton>
				</footer>
			</Popup>
		</transition>
	</article>
</template>

<script>
import defaultRemoving from "@/js/mixins/defaultRemoving.mixin";
import CampaignStatisticsDisplaying from "@/js/components/Campaign/CampaignStatisticsDisplaying";
import searchLeadsType from "@/js/mixins/searchLeadsType.mixin";
import { downloadFileHandler } from "@/js/helpers/utils";
import { EMAIL_RATE_FEATURE } from "@/js/helpers/constants/restrictionsExclude";

export default {
	name: "LeadsPack",
	components: {
		LeadsIndicators: () =>
			window.compLoader(import("@/js/components/Campaign/LeadsIndicators/LeadsIndicators")),
		LeadsReport: () => window.compLoader(import("@/js/components/Campaign/Audience/LeadsReport")),
		SearchSource: () => window.compLoader(import("@/js/components/Campaign/Audience/SearchSource")),
		CircleProgress: () =>
			window.compLoader(import("@/js/components/Campaign/Audience/CircleProgress")),
		CampaignStatisticsDisplaying
	},
	mixins: [defaultRemoving, searchLeadsType],

	props: {
		pack: { type: Object, required: true },
		campaignCreating: { type: Boolean, default: false },
		leadsInAudience: { type: Boolean, default: false },
		isDraft: { type: Boolean, default: false },
		statisticsLoading: { type: Boolean, default: false },
		campaign: { type: Object, required: false }
	},
	data: () => ({
		reportPopup: false,
		isEmailReplyFeatureVisible: false
	}),
	computed: {
		campaignIsActive() {
			return this.campaign.status === "ACTIVE";
		},
		searchComplete() {
			return this.pack.searchStatus === "SEARCH_COMPLETE";
		},
		sleepModeEnabled() {
			return this.$store.getters.sleepModeEnabled;
		},
		searchPaused() {
			return this.pack.searchStatus === "SEARCH_PAUSED";
		},
		searchPausedTooltip() {
			if (!this.searchComplete) {
				if (this.searchPaused) return this.pauseErrorText;
				if (!this.campaignIsActive)
					return "Since your campaign is paused, Dripify stopped adding leads from LinkedIn to this list. Enable your campaign to resume the process";
				if (this.sleepModeEnabled)
					return "Since your campaigns are out of working hours at the moment, Dripify stopped adding leads from LinkedIn. Adjust your schedule to resume the process";
				else return null;
			} else return null;
		},
		leadsURL() {
			return `/campaigns/${this.campaign.id}/leads/?leadsList=${this.pack.id}`;
		},
		totalLeadsCount() {
			return this.pack.statistics ? this.pack.statistics.totalLeadsCount : 0;
		},
		packName() {
			return this.searchLeadsPackName(this.pack);
		},
		pauseErrorText() {
			switch (this.pack.error) {
			case "SEARCH_WRONG_CONTRACT":
				return "The lead transfer to the list is stopped due to the LinkedIn contract mismatch";
			case "SEARCH_GENERIC_ERROR":
				return "The lead transfer to the list is stopped since the LinkedIn search URL is invalid";
			case "SEARCH_SESSION_IS_TAKEN":
				return "The lead transfer to the list is stopped since you have another live session on LinkedIn";
			case "SEARCH_MULTIPLE_RECRUITER_SESSIONS":
				return "The lead transfer to the list has been stopped due to multiple sign-ins in Recruiter";
			case "SEARCH_2FA_IS_NOT_ENABLED":
				return "The lead transfer to the list is stopped since a LinkedIn contract has been disconnected";
			default:
				return "There has been an error while trying to retrieve profiles from LinkedIn, therefore Dripify stopped adding leads to this list";
			}
		}
	},
	created() {
		this.isEmailReplyFeatureVisible = EMAIL_RATE_FEATURE.includes(this.$cu().email);
	},
	methods: {
		async downloadFile() {
			const res = await this.$store.dispatch("getLeadsIdsFile", {
				campaignId: this.campaign.id,
				searchLeadConfigItemId: this.pack.id
			});

			downloadFileHandler({
				href: window.URL.createObjectURL(new Blob([res.data])),
				download: `${this.campaign.name} - Leads IDs.csv`
			});
		},
		async removeLeadPack() {
			await this.$store.dispatch("deleteSearchLeadsConfigItem", this.pack.id);
			this.$emit("removed");
			this.closeRemovingPopup();
		},
		showReport() {
			this.reportPopup = true;
		},
		hideReport() {
			this.reportPopup = false;
		}
	}
};
</script>

<style lang="sass">
.leads-pack
	$this: &
	border-radius: 10px
	border: 1px solid cl(light-dirty)
	background-color: cl(w)
	padding: 10px 15px
	margin-bottom: 10px
	@media screen and (max-width: $tablet)
		padding: 15px
	&__
		&grid
			display: grid
			grid-template-columns: 60px minmax(195px, 220px) 95px minmax(120px, 2.5fr) 0.2fr 1fr 1fr 0.2fr minmax(90px, 1fr) 10px
			grid-template-areas: "picto main total status separ1 accept reply separ2 date context"
			align-items: center
			gap: 10px
			@media screen and (max-width: $netbook)
				grid-template-columns: 48px auto auto auto auto 10px
				grid-template-areas: "picto main main main main context" "status status status status total total" "reply reply accept date date date"
			&--email
				grid-template-columns: 60px minmax(195px, 220px) 95px minmax(120px, 2.5fr) 0.2fr 1fr 1fr 0.2fr 1fr 1fr 0.2fr minmax(90px, 1fr) 10px
				grid-template-areas: "picto main total status separ1 accept reply separ2 email-open email-reply separ3 date context"
				@media screen and (max-width: $netbook)
					grid-template-columns: 48px auto auto auto auto 10px
					grid-template-areas: "picto main main main main context" "status status status status total total" "reply reply accept date date date"  "email-open email-open email-open email-reply email-reply email-reply"
		&main
			@media screen and (min-width: $tablet)
				padding-right: 20px
		&name
			display: inline-block
			max-width: 100%
			font-size: 16px
			overflow: hidden
			text-overflow: ellipsis
			white-space: nowrap
			color: cl(b)
			@media screen and (max-width: $netbook)
				font-size: 15px
			&--link
				color: cl(base-theme)
				&:hover
					text-decoration: underline
		&indicators
			margin-top: 7px
		&separator
			width: 1px
			height: 60px
			background-color: cl(light-dirty)
			@media screen and (max-width: $netbook)
				display: none
		&small
			display: block
			font-size: 12px
			color: cl(_gray)
		&collected
			width: 54px
			height: 54px
			border-radius: 50%
			background: radial-gradient(circle, cl(soft-theme) 20px, cl(easy-blue) 21px)
			position: relative
			&::after
				content: ''
				width: 100%
				height: 100%
				display: block
				background: center no-repeat url("data:image/svg+xml,%3Csvg width='17' height='12' viewBox='0 0 17 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 1L5.6875 11L1 6.45455' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
				background-size: 21px
		&from
			display: flex
			align-items: center
			gap: 10px
			@media screen and (max-width: $netbook)
				display: none
		&status
			display: grid
			align-items: center
			gap: 10px
			grid-template-columns: 54px auto
		&status-text
			display: inline-flex
			align-items: center
			gap: 4px
		&report-button
			font-size: 13px
			color: cl(base-theme)
			margin-top: 3px
			&:hover
				text-decoration: underline
		&context-added-from
			@media screen and (min-width: $netbook)
				display: none
</style>
